<template>
  <div class="page">
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          <span>停车场:{{ parkingOtherData.parkingLotNumber }}</span>
          <span style="margin-left: 100px">泊位数量:{{ parkingOtherData.totalBerthsNumber }}</span>
        </div>
        <div class="op-extra">
          <el-button size="mini" type="primary" @click="doRelation"
          >关联车场
          </el-button
          >
          <icon-button slot="extra" icon="return" @click.native="goBack"/>
        </div>
      </div>
      <el-table
          v-loading="isLoading"
          :data="parkingInfo"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="车场编号" prop="parkingLotNum">
        </el-table-column>
        <el-table-column label="车场名称" prop="parkingLotName">
        </el-table-column>
        <el-table-column label="城区" prop="regionName"></el-table-column>
        <el-table-column label="车场位置" prop="parkingLotLocation"></el-table-column>
        <el-table-column label="泊位数量" prop="berthsNumber"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="goDelete(scope.row.id)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pageNo"
            :page-size="pageSize"
            :page-sizes="[10, 15, 30, 50]"
            :total="total"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!--关联车场-->
    <el-dialog
        v-loading="isLoading"
        :before-close="beforeRelationDialogClosed"
        :visible.sync="relationConfigDialogVisible"
        append-to-body
        class="common-dialog"
        title="关联车场"
        width="800px"
    >
      <SuggestParking v-model="parkingLotIds" :multiple="isMultiple" style="width:600px"/>
      <!-- <div class="add-btn">
          <el-button type="primary" @click="addParkingLot">添加</el-button>
        </div> -->

      <!-- <div>
          <el-table
            :data="relationList"
            border
            style="width: 100%"
            class="common-table"
            size="medium"
            v-loading="isLoading"
          >
            <el-table-column label="车场名称">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="common-text"
                  @click="deleteParkingLot(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div> -->

      <span slot="footer" class="dialog-footer" style="text-align: right;">
        <el-button @click="cancelRelation">取 消</el-button>
        <el-button type="primary" @click="saveRelation">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
import {addSettlementParking, deleteSettlementParking, findParkingBySettlementId} from "@/api/settlementManage";

export default {
  components: {
    SuggestParking,
  },
  data() {
    return {
      isLoading: false,
      settlementId: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      parkingOtherData: {}, //车场数量，泊位数量
      parkingInfo: [],
      isMultiple: true, //停车场多选
      relationConfigDialogVisible: false, //添加关联车场弹窗
      parkingLotIds: [], //关联多个车场id
    };
  },
  methods: {
    //关联车场弹窗
    doRelation() {
      this.relationConfigDialogVisible = true;
    },
    //取消关联车场
    cancelRelation() {
      this.relationConfigDialogVisible = false;
      this.parkingLotIds = [];
    },
    //关闭弹窗钩子
    beforeRelationDialogClosed(done) {
      this.parkingLotIds = [];
      done();
    },
    //保存关联车场
    saveRelation() {
      const body = {
        settlementId: this.settlementId,
        parkingLotId: this.parkingLotIds,
      };
      this.isLoading = true;
      addSettlementParking(body).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "关联车场成功",
            type: "success",
          });
          this.relationConfigDialogVisible = false;
          this.parkingLotIds = [];
          this.getParkingList();
        }
      });
    },
    //删除关联车场
    goDelete(e) {
      this.$confirm("确定要删除该车场吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const parkingLotId = [];
        parkingLotId.push(e)
        const body = {
          parkingLotId: parkingLotId,
          settlementId: this.settlementId
        }
        this.isLoading = true;
        deleteSettlementParking(body).then((res) => {
          this.isLoading = false;
          if (res && res.code === 30 && res.result) {
            this.$message.success("删除成功！");
            this.getParkingList();
          }
        });
      });
    },

    //查询停车场列表
    getParkingList() {
      const id = this.settlementId;
      const pageNo = this.pageNo;
      const pageSize = this.pageSize;
      this.isLoading = true;
      findParkingBySettlementId(id, pageSize, pageNo).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.parkingInfo = res.returnObject.list;
          this.parkingOtherData = {
            totalBerthsNumber: res.returnObject.totalBerthsNumber,
            parkingLotNumber: res.returnObject.parkingLotNumber,
          };
          this.total = res.returnObject.total;
        }
      })
    },
    //停车场分页查询
    handleSizeChange(e) {
      this.pageSize = e;
      this.getParkingList();
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.getParkingList();
    },
    //返回结算方管理页面
    goBack() {
      this.$router.back();
    },
  },
  mounted() {
    const settlementId = this.$route.query.id;
    this.settlementId = settlementId;
    this.getParkingList();
  },
};
</script>

<style lang="less" scoped>
</style>
